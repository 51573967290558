import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import VirtpayPayinRequest from '../../../../../virtpay-transactions-services/models/payin/VirtpayPayinRequest';
import VirtpayPayoutRequest from '../../../../../virtpay-transactions-services/models/payout/VirtpayPayoutRequest';
import VirtpayPayoutHttpRequest from '../../../../../aba/cabinet/web/models/VirtpayPayoutHttpRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import VirtpayPayinResponse from '../../../../../virtpay-transactions-services/models/payin/VirtpayPayinResponse';
import InfoForVirtpay from '../../../../../transaction-services/models/transfer-info-service/InfoForVirtpay';
import VirtpayPayoutResponse from '../../../../../virtpay-transactions-services/models/payout/VirtpayPayoutResponse';

export default class VirtpayWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	PayinEvent = new Events<WsResponseModelT<VirtpayPayinResponse>>();
	CheckVirtpayTransferEvent = new Events<WsResponseModelT<InfoForVirtpay>>();
	PayoutEvent = new Events<WsResponseModelT<VirtpayPayoutResponse>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('VirtpayWsController', 'Payin', 'Main', (res: WsResponseModelT<VirtpayPayinResponse>) => {
			self.PayinEvent.trigger(res);
		});
		webApiService.on('VirtpayWsController', 'CheckVirtpayTransfer', 'Main', (res: WsResponseModelT<InfoForVirtpay>) => {
			self.CheckVirtpayTransferEvent.trigger(res);
		});
		webApiService.on('VirtpayWsController', 'Payout', 'Main', (res: WsResponseModelT<VirtpayPayoutResponse>) => {
			self.PayoutEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async Payin(request: VirtpayPayinRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'VirtpayWsController',
			Method: 'Payin',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async CheckVirtpayTransfer(request: VirtpayPayoutRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'VirtpayWsController',
			Method: 'CheckVirtpayTransfer',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async Payout(request: VirtpayPayoutHttpRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'VirtpayWsController',
			Method: 'Payout',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async payinAsync(request: VirtpayPayinRequest): Promise<VirtpayPayinResponse> {
		return new Promise<VirtpayPayinResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'VirtpayWsController',
				Method: 'Payin',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<VirtpayPayinResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as VirtpayPayinResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkVirtpayTransferAsync(request: VirtpayPayoutRequest): Promise<InfoForVirtpay> {
		return new Promise<InfoForVirtpay>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'VirtpayWsController',
				Method: 'CheckVirtpayTransfer',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<InfoForVirtpay>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as InfoForVirtpay)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async payoutAsync(request: VirtpayPayoutHttpRequest): Promise<VirtpayPayoutResponse> {
		return new Promise<VirtpayPayoutResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'VirtpayWsController',
				Method: 'Payout',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<VirtpayPayoutResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as VirtpayPayoutResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}