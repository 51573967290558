
import EnumCurrency from "@/api/main/dataSource/api/aba/data/enums/EnumCurrency";
import ModelPersonBalance from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import GetNewUserResponse from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUserResponse";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import { useRoute, useRouter } from "vue-router";
import { AdapterProfileService } from "../../profile";

import * as Routes from "@/router/routerNames";
import { ref } from "vue";
@Options({
  name: "acquiring-refill",
})
export default class AcquiringRefill extends Vue {
  @Prop({ default: false }) visible: boolean;
  @Prop({ default: null }) Profile: GetNewUserResponse;
  @Prop({ default: null }) Balance: ModelPersonBalance;

  ErrorMessage: string = "";
  Amount: string = "";
  IsSuccess: boolean = false;
  Commission = 0;
  IsLoad: boolean = false;
  AcquiringPayinSuccess: boolean = null;
  router = useRouter();

  mounted() {
    this.init();
  }

  close() {
    this.$emit("close");
  }

  @Watch("Amount") async getCommision() {
    if (this.Amount == "") {
      this.Commission = 0;
      return;
    }
    const resp = await this.adapter.getCommission({
      Amount: Number(this.Amount),
      Type: 33,
    });
    this.Commission = Number(resp.Value);
  }

  @Watch("visible")
  init() {
    if (!this.visible) return;
    var par = this.getParameterByName("AcquiringPayinSuccess");
    if (!!par) {
      this.AcquiringPayinSuccess = par.toLowerCase() == "true";
      document
        .getElementById("open_account_replenishment_acquiring_popup")
        .click();
      //$('#account_replenishment_acquiring_popup').magnificPopup('open');
    }
    window.history.pushState(
      {},
      document.title,
      location.href.replace(
        "AcquiringPayinSuccess=" +
          this.getParameterByName("AcquiringPayinSuccess"),
        ""
      )
    );

    console.log(
      "AcquiringRefillCreated",
      this.AcquiringPayinSuccess,
      location.href.replace(
        "AcquiringPayinSuccess==" +
          this.getParameterByName("AcquiringPayinSuccess"),
        ""
      )
    );
  }
  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  adapter = AdapterProfileService.getInstance();

  async replenishment() {
    if (this.IsLoad) return;
    this.IsLoad = true;

    var formData = new FormData();
    formData.append("Amount", this.Amount.toString());

    //TODO
    const res = await this.adapter.payn({
      Amount: Number(this.Amount),
      Currency: EnumCurrency.EUR,
      PaymentDescription: undefined,
      UserId: undefined,
      PaymentPurpose: undefined,
      ReturnUrl: window.location.origin + "/natural/transaction",
    });
    if (res.IsSuccess) {
      // this.router.push({ name: Routes.NATURALPERSON_BALANCE });
      window.location.href = res.Value.UrlRedirect;
      return;
    } else {
      this.ErrorMessage = res.ErrorMessage;
    }
    this.IsLoad = false;
  }
}
