import ApiService from "@/api/index";
import ChangePasswordRequest from "@/api/main/dataSource/api/aba/cabinet/web/models/ChangePasswordRequest";
import CurrentUserResponse from "@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse";
import GetCommissionRequest from "@/api/main/dataSource/api/aba/cabinet/web/models/GetCommissionRequest";
import GetPhotosResponse from "@/api/main/dataSource/api/aba/cabinet/web/models/GetPhotosResponse";
import VirtpayWsController from "@/api/main/dataSource/api/aba/cabinet/web/ws-controllers/accounts/VirtpayWsController";
import EnumLang from "@/api/main/dataSource/api/i-public-service/aba-finance/models/enums/EnumLang";
import ModelMessage from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelMessage";
import ModelPersonBalance from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelPersonBalance";
import ModelSystemMessages from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelSystemMessages";
import MailBankModel from "@/api/main/dataSource/api/i-public-service/aba-finance/models/support/MailBankModel";
import ModelLegalPerson from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelLegalPerson";
import ModelNaturalPerson from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson";
import ModelPersonIdentification from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelPersonIdentification";
import AcquiringInitPayinRequest from "@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinRequest";
import AcquiringInitPayinResponse from "@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinResponse";
import MoneyTransactionPersonResponse from "@/api/main/dataSource/api/transaction-services/models/MoneyTransactionPersonResponse";
import TransactionPersonRequest from "@/api/main/dataSource/api/transaction-services/models/TransactionPersonRequest";
import AcquiringPayoutTemplateListResponse from "@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse";
import VirtpayPayinRequest from "@/api/main/dataSource/api/virtpay-transactions-services/models/payin/VirtpayPayinRequest";
import VirtpayPayinResponse from "@/api/main/dataSource/api/virtpay-transactions-services/models/payin/VirtpayPayinResponse";
import EnumTypeUser from "@/api/main/models/Enums/EnumTypeUser";
import UserType from "@/api/main/models/Enums/UserType";
import WsResponseModel from "@/api/main/SchemaBuilderServer/Models/WsResponseModel";
import GetNaturalPersonResponse from "@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse";
import MessageItemModel from "@/api/main/services/NaturalPersonProfile/models/MessageItemModel";
import ModelPersonCard from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard";
import GetSystemMessages from "@/api/main/services/NaturalPersonProfile/models/response/GetSystemMessages";
import FAQQuestionModel from "@/api/main/services/NaturalPersonSupport/models/FAQQuestionModel";

import { notify } from "@/app/notifications";
import { PersonModel } from "@/views/models";
import FAQCategoryModel from "@/views/models/FAQCategoryModel";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import ProfileModel from "@/views/models/ProfileModel";
import moment from "moment";

import { Ref, ref } from "vue";

export default class AdapterProfileService {
  /**
   * Сервис
   */
  private static instance: AdapterProfileService;
  /**
   * API
   */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  naturalPerson = ref(new ModelNaturalPerson());
  api: ApiService;
  Person: Ref<PersonModel> = ref(new PersonModel());

  constructor() {
    AdapterProfileService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterProfileService.instance) {
      AdapterProfileService.instance = new AdapterProfileService();
    }
    return AdapterProfileService.instance;
  }

  async checkIdentified(): Promise<boolean> {
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.checkIdentifiedAsync();
      return res as boolean;
    } catch (err: any) {
      this.showError(err);
      return null;
    }
  }

  async getCurrentUser(): Promise<WsResponseModel<CurrentUserResponse>> {
    const result = new WsResponseModel<CurrentUserResponse>();
    try {
      const resp =
        await AdapterProfileService.api.Main.AuthWsController.getCurrentUserAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCurrentUser");
    }
    return result;
  }

  async getCommission(
    req: GetCommissionRequest
  ): Promise<WsResponseModel<Number>> {
    const result = new WsResponseModel<Number>();
    try {
      const resp =
        await AdapterProfileService.api.Main.CommissionsWsController.getCommissionAsync(
          req
        );
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCommission");
    }
    return result;
  }

  async getPerson(): Promise<PersonModel> {
    if (this.Person?.value?.Id) {
      return this.Person.value;
    }
    const res = await this.getCurrentUser();
    if (!res.IsSuccess) {
      return;
    }
    if (res.Value.UserRole == UserType.Natural) {
      await this.getNaturalPerson();
      return this.Person.value;
    }
    await this.getLegalPerson();
    return this.Person.value;
  }

  async getNaturalPerson(): Promise<WsResponseModel<ModelNaturalPerson>> {
    const result = new WsResponseModel<ModelNaturalPerson>();
    try {
      const resp =
        await AdapterProfileService.api.Main.ProfileWsController.getNaturalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map((el) => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName,
        Email: resp.Email,
        Id: resp.Id,
        Identified: resp.Identified,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      });
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getNaturalPerson");
    }
    return result;
  }

  async getLegalPerson(): Promise<WsResponseModel<ModelLegalPerson>> {
    const result = new WsResponseModel<ModelLegalPerson>();
    try {
      const resp =
        await AdapterProfileService.api.Main.ProfileWsController.getLegalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map((el) => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName,
        Email: resp.Email,
        Id: resp.Id,
        Identified: resp.IdentifiedId,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      });
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getLegalPerson");
    }
    return result;
  }

  convertModelNaturalPersonToNaturalPersonModel(
    value: ModelNaturalPerson
  ): NaturalPersonModel {
    return new NaturalPersonModel({
      LastName: value.LastName,
      Name: value.Name,
      Phone: value.Phone,
      Email: value.Email,
      Identified: value.Identified,
      IdentifiedId: value.IdentifiedId,
      ConfrimHash: value.ConfrimHash,
      IsConfirmPhone: value.IsConfirmPhone,
      CardId: value.CardId,
      NumberCard: value.NumberCard,
      NumberPassport: value.NumberPassport,
      RegistrationAddress: value.RegistrationAddress,
      SearchFilter: value.SearchFilter,
      Search: value.Search,
      Appeal: value.Appeal,
      Balance: value.Balance,
      Cards: value.Cards,
      BirthDate: value.BirthDate ? value.BirthDate.toISOString() : null, // Преобразование даты в строку, если требуется
      AcquiringCardMask: value.AcquiringCardMask,
    });
  }

  convertModelPersonIdentificationToProfilModel(
    value: ModelPersonIdentification
  ): ProfileModel {
    const res = new ProfileModel({
      Identified: value.Identified,
      Appeal: value.Appeal,
      Id: value.Id,
      Phone: value.Phone,
      Email: value.Email,

      BirthDate: value.BirthDate,
      // BirthPlace: value.Bir = null;
      AddressState: value.AddressState,
      AddressCountry: value.AddressCountry,
      AddressCity: value.AddressCity,
      AddressStreet: value.AddressStreet,
      AddressZip: value.AddressZip,
      DocumentType: value.DocumentType,
      DocumentNumber: value.DocumentNumber,
      // DocumentIssuedCountryCode: value.Do
      // DocumentIssuedBy: value.D
      FirstName: value.FirstName,
      SecondName: value.SecondName,
      // PassportNumber:value.P
      Country: value.AddressCountry,
      Code: value.AddressZip,
      City: value.AddressCity,
      Street: value.AddressStreet,
      BirthPlace: value.BirthPlace,
      DocumentIssuedBy: new Date(value.DocumentIssuedBy),
      DocumentIssuedCountryCode: value.DocumentIssuedCountryCode,
      // ExtraBedForAnApartment:value.

      // HouseNumber: value.
      // ExtraBedForAnApartment:value
      PostCode: value.AddressZip,
    });
    return res;
  }
  convertModelNaturalPersonToProfilModel(
    value: ModelNaturalPerson
  ): ProfileModel {
    return new ProfileModel({
      Identified: value.Identified,
      Appeal: value.Appeal,
      Id: value.Id,
      Phone: value.Phone,
      Email: value.Email,

      BirthDate: value.BirthDate,
      // BirthPlace: value.Bir = null;
      AddressState: value.AddressState,
      AddressCountry: value.AddressCountry,
      AddressCity: value.AddressCity,
      AddressStreet: value.AddressStreet,
      AddressZip: value.AddressZip,
      DocumentType: value.DocumentType,
      DocumentNumber: value.DocumentNumber,
      // DocumentIssuedCountryCode: value.Do
      // DocumentIssuedBy: value.D
      FirstName: value.Name,
      SecondName: value.LastName,
      PassportNumber: value.NumberPassport,
      Country: value.AddressCountry,
      Code: value.AddressZip,
      City: value.AddressCity,
      Street: value.AddressStreet,
      // HouseNumber: value.
      // ExtraBedForAnApartment:value
      PostCode: value.AddressZip,
    });
  }

  toGetNaturalPersonResponse(
    value: ModelNaturalPerson
  ): GetNaturalPersonResponse {
    return new GetNaturalPersonResponse({
      Identified: value.Identified,
      IdentifiedId: value.IdentifiedId,
      ConfrimHash: value.ConfrimHash,
      IsConfirmPhone: value.IsConfirmPhone,
      CardId: value.CardId,
      NumberCard: value.NumberCard,
      NumberPassport: value.NumberPassport,
      RegistrationAddress: value.RegistrationAddress,
      SearchFilter: value.SearchFilter,
      Search: value.Search,
      Appeal: value.Appeal,
      Balance: value.Balance,
      Cards: value.Cards,
      BirthDate: value.BirthDate ? value.BirthDate.toISOString() : null, // Преобразование даты в строку, если требуется
      AcquiringCardMask: value.AcquiringCardMask,
    });
  }
  toNaturalPerson(req: GetNaturalPersonResponse): NaturalPersonModel {
    return new NaturalPersonModel(req);
  }
  dateToString(req: Date): string {
    return moment(req).format("DD.MM.YYYY HH:mm");
  }

  mapModelNaturalPersontoNaturalPerson(
    req: ModelNaturalPerson
  ): NaturalPersonModel {
    return new NaturalPersonModel({
      AcquiringCardMask: req.AcquiringCardMask,
      Address: req.Address,
      AllowMassTransfers: req.AllowMassTransfers,
      Appeal: req.Appeal,
      Balance: req.Balance,
      BirthDate: this.dateToString(req.BirthDate),
      CardId: req.CardId,
      Cards: req.Cards,
      CompanyName: req.CompanyName,
      ConfrimHash: req.ConfrimHash,
      DateLastMessage: this.dateToString(req.DateLastMessage),
      DateViewMessage: this.dateToString(req.DateLastMessage),
      Email: req.Email,
      Id: req.Id,
      Identified: req.Identified,
      IdentifiedId: req.IdentifiedId,
      IsConfirmPhone: req.IsConfirmPhone,
      IsHaveCards: req.IsHaveCards,
      IsTwoFactorAuthentication: req.IsTwoFactorAuthentication,
      LastName: req.LastName,
      Login: req.Login,
      Name: req.Name,
      NumberCard: req.NumberCard,
      NumberPassport: req.NumberPassport,
      Password: req.Password,
      PaymentsType: req.PaymentsType,
      Phone: req.Phone,
      RegistrationAddress: req.RegistrationAddress,
      Role: req.Role,
      Search: req.Search,
      SearchFilter: req.AcquiringCardMask,
      Token: req.Token,
      TypeIdentified: req.TypeIdentified,
      TypeUser: req.TypeUser,
      TypeUserId: this.convertUserTypeToEnumTypeUser(req.TypeUserId),
    });
  }
  convertUserTypeToEnumTypeUser(req: UserType): EnumTypeUser {
    let result = EnumTypeUser.LegalPerson;
    if (req == UserType.Natural) {
      result = EnumTypeUser.NaturalPerson;
    }
    return result;
  }

  // async getNaturalPerson(): Promise<GetNaturalPersonResponse> {
  //   try {
  //     const res = await AdapterProfileService.api.Main.ProfileWsController.getNaturalPersonAsync();
  //     this.naturalPerson.value = res;
  //     localStorage.setItem("NaturalPerson", JSON.stringify(res))
  //     return this.toGetNaturalPersonResponse(res)
  //   } catch (err: any) {
  //     this.showError(err)
  //     return null
  //   }
  // }
  /**
   * образец
   * @returns
   */
  async GetNaturalPerson(): Promise<WsResponseModel<NaturalPersonModel>> {
    const result = new WsResponseModel<NaturalPersonModel>();
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.getNaturalPersonAsync();
      this.naturalPerson.value = res;
      // localStorage.setItem("NaturalPerson", JSON.stringify(res))
      result.Value = this.mapModelNaturalPersontoNaturalPerson(res);
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  // async changeDefense(twoAuth: boolean = false): Promise<boolean> {
  //   try {
  //     const res = await AdapterProfileService.api.Main.SecurityWsController.changeDefenseAsync();
  //     return res as boolean
  //   } catch (err: any) {
  //     this.showError(err)
  //     return null
  //   }
  // }
  async changeDefense(twoAuth?: boolean): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const res =
        await AdapterProfileService.api.Main.SecurityWsController.changeDefenseAsync(
          twoAuth
        );
      // localStorage.setItem("NaturalPerson", JSON.stringify(res))
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR changeDefense");
    }
    return result;
  }

  async checkDefense(): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const res =
        await AdapterProfileService.api.Main.SecurityWsController.checkDefenseAsync();
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkDefense");
    }
    return result;
  }

  async getQrImage(): Promise<WsResponseModel<String>> {
    const result = new WsResponseModel<String>();
    try {
      const res =
        await AdapterProfileService.api.Main.SecurityWsController.getQrImageAsync();
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getQrImage");
    }
    return result;
  }
  async chagePassword(
    req: ChangePasswordRequest
  ): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const res =
        await AdapterProfileService.api.Main.SecurityWsController.changePasswordAsync(
          req
        );
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR chagePassword");
    }
    return result;
  }

  async getPhotos(): Promise<GetPhotosResponse> {
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.getPhotosAsync();
      return res;
    } catch (err: any) {
      this.showError(err);
      throw err;
    }
  }
  toGetSystemMessages(
    val: Array<ModelSystemMessages>
  ): Array<GetSystemMessages> {
    return val.map(
      (el) =>
        new GetSystemMessages({
          DateCreate: el.DateCreate.toISOString(),
          Id: el.Id,
          IsRead: el.IsRead,
          SystemMessagesLangs: el.SystemMessagesLangs,
          Text: el.Text,
          TypeSystemMessageId: el.TypeSystemMessageId,
          UserId: el.UserId,
        })
    );
  }

  async acquiringPayin(
    req: AcquiringInitPayinRequest
  ): Promise<WsResponseModel<AcquiringInitPayinResponse>> {
    const result = new WsResponseModel<AcquiringInitPayinResponse>();
    try {
      const res =
        await AdapterProfileService.api.Main.NaturalPaymentsWsController.acquiringPayinAsync(
          req
        );

      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR acquiringPayin");
    }
    return result;
  }

  async payn(
    req: VirtpayPayinRequest
  ): Promise<WsResponseModel<VirtpayPayinResponse>> {
    const result = new WsResponseModel<VirtpayPayinResponse>();
    try {
      const res =
        await AdapterProfileService.api.Main.VirtpayWsController.payinAsync(
          req
        );
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR acquiringPayin");
    }
    return result;
  }

  async getInternalTransactionsNew(
    req: TransactionPersonRequest
  ): Promise<MoneyTransactionPersonResponse> {
    try {
      const res =
        await AdapterProfileService.api.Main.InternalBalanceWsController.getInternalTransactionsNewAsync(
          req
        );
      return res;
    } catch (err: any) {
      this.showError(err);
      throw err;
    }
  }

  async readingSystemMessagesAll(): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.readingSystemMessagesAllAsync();
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  /**
   * образец
   * @returns
   */
  async getAcquiringTemplates(): Promise<
    WsResponseModel<AcquiringPayoutTemplateListResponse>
  > {
    const result = new WsResponseModel<AcquiringPayoutTemplateListResponse>();
    try {
      const resp =
        await AdapterProfileService.api.Main.NaturalPaymentsWsController.getAcquiringTemplatesAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  /**
   * Отпрака сообщения
   * @returns
   */
  async addMessage(req: string): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.addMessageAsync(
          req
        );
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  async getSystemMessages(): Promise<
    WsResponseModel<Array<ModelSystemMessages>>
  > {
    const result = new WsResponseModel<Array<ModelSystemMessages>>();
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.getSystemMessagesAsync();
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getSystemMessages");
    }
    return result;
  }
  //для чата в разделе сообщений
  async getMessages(): Promise<WsResponseModel<Array<ModelMessage>>> {
    const result = new WsResponseModel<Array<ModelMessage>>();
    try {
      const res =
        await AdapterProfileService.api.Main.ProfileWsController.getMessagesAsync();
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getMessages");
    }
    return result;
  }

  mapModelMessagesToMessageItemModel(req: ModelMessage[]): MessageItemModel[] {
    const res = req.map(
      (el) =>
        new MessageItemModel({
          DateCreate: this.dateToString(el.DateCreate),
          DateCreateJs: el.DateCreate,
          Id: el.Id,
          IsPersonMessage: el.IsPersonMessage,
          Text: el.Text,
          UserId: el.UserId,
        })
    );
    return res;
  }
  mapModelSystemMessagesToGetSystemMessages(
    req: ModelSystemMessages[]
  ): GetSystemMessages[] {
    const res = req.map(
      (el) =>
        new GetSystemMessages({
          DateCreate: this.dateToString(el.DateCreate),
          Id: el.Id,
          IsRead: el.IsRead,
          SystemMessagesLangs: el.SystemMessagesLangs,
          Text: el.Text,
          UserId: el.UserId,
        })
    );
    return res;
  }

  /**
   * Универсальный обработчик ошибок
   * @param e
   * @param context
   * @returns
   */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || "Internal Server Error",
      Value: null,
    };
  }
  /**
   * показать информацию об ошибке
   * @param err
   */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: "error" });
  }
}
