
import MoneyTransactionPersonItem from "@/api/main/services/NaturalPersonInternalBalance/models/MoneyTransactionPersonItem/MoneyTransactionPersonItem";
import HistoryItemModel from "@/api/main/services/NaturalPersonPayments/models/HistoryItemModel";
import ModelPersonBalance from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import ModelPersonCard from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard";
import DatePeriodModel from "@/views/models/DatePeriodModel";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import PaymentParamModel from "@/views/models/PaymentParamModel";
import cookieService from "@/api/main/services/Cookie/cookie.service";
import moment from "moment";
import { Vue, Options, Watch } from "vue-property-decorator";
import CookieService from "@/api/main/services/Cookie/cookie.service";
import UpdateCardTransactionModel from "@/api/main/services/NaturalPersonPayments/models/UpdateCardTransactionModel";
import { AdapterTransactionService } from "./adapter";
import EnumTransactionType from "@/api/main/dataSource/api/aba/data/enums/EnumTransactionType";
import EnumDocumentType from "@/api/main/dataSource/api/common/enums/EnumDocumentType";
import DownloadTransactionsForNaturalRequest from "@/api/main/dataSource/api/i-public-service/aba-finance/models/transaction/DownloadTransactionsForNaturalRequest";
import { PersonModel } from "@/views/models";
@Options({
  name: "naturalperson-payments-historyOperation",
})
export default class NaturalpersonPaymentsHistoryOperation extends Vue {
  TypeArray: Array<string> = ["Internal account", "Card"];
  modelType: string = "Internal account";
  Type: string = "Account"; //"Card"
  CookieTransactionUpd: string = "lastUpdTrans";

  Person: PersonModel = null;
  items: Array<UpdateCardTransactionModel> = null;
  itemsInternal: Array<MoneyTransactionPersonItem> | Array<HistoryItemModel> =
    null;
  Card: ModelPersonCard = null;
  Balance: ModelPersonBalance = null;
  monthTransations: any = null;
  monthNames: Array<string> = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "майя",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  Count: number = 0;
  Consumption: number = 0;
  ConsumptionInternal: number = 0;
  Income: number = 0;
  IncomeInternal: number = 0;

  CreateDateStart: Date = null;
  CreateDateEnd: Date = null;

  IsPeriod: boolean = false;
  IsOpenSelectDownload: boolean = false;
  IsLoadPerson: boolean = false;
  isOpenDatepicker: boolean = false;

  @Watch("modelType")
  onChangeModelType() {
    switch (this.modelType) {
      case "Internal account":
        this.Type = "Account";
        break;
      case "Card":
        this.Type = "Card";
        break;
    }
    var params = new PaymentParamModel();
    this.GetHistory(params);
  }
  adapter = AdapterTransactionService.getInstance();

  async mounted() {
    await this.getPerson();
    // this.setPerson();
    await this.init();
  }
  // async getPerson() {
  // 	this.IsLoadPerson = true;
  //
  // 	const res = await this.adapter.getNaturalPerson();
  // 	if (res.IsSuccess) {
  // 		this.Person = this.adapter.mapModelNaturalPersontoNaturalPerson(res.Value);
  // 		this.Balance = this.Person.Balance;
  // 		this.Card = new ModelPersonCard();
  // 		if (
  // 			this.Person.Cards.length > 0 &&
  // 			this.Person.Cards.some(function (item) {
  // 				// return item.Status === 1;
  // 			})
  // 		) {
  // 			this.Card = this.Person.Cards[0];
  // 		}
  // 	}
  // 	this.IsLoadPerson = true;
  // }

  async getPerson() {
    const resp = await this.adapter.getPerson();
    if (resp) {
      this.Person = resp;
      this.IsLoadPerson = true;
    }
  }

  async init() {
    var now = new Date();
    this.CreateDateStart = new Date(now.getFullYear(), now.getMonth() - 3, 1);
    this.CreateDateEnd = now.setDate(now.getDate() + 1) ? now : now;

    var params = new PaymentParamModel();
    await this.GetHistory(params);

    this.UpdateTransactionHash();
  }

  closeDropdown() {
    setTimeout(() => {
      this.IsOpenSelectDownload = false;
    }, 80);
  }
  switchDropdownDownload() {
    this.IsOpenSelectDownload = !this.IsOpenSelectDownload;
  }

  ChangePeriod(date: DatePeriodModel) {
    this.CreateDateStart = date.StartDate;
    this.CreateDateEnd = date.EndDate;
    var params = new PaymentParamModel();
    this.GetHistory(params);
  }

  async getTransactions(params: PaymentParamModel) {
    let formData = new FormData();
    formData.append("FromDate", params.Filter.DateCreateStart);
    formData.append("ToDate", params.Filter.DateCreateEnd);
    //TODO api отличается

    const adapter = AdapterTransactionService.getInstance();

    const res = await adapter.getInternalTransactionsNew({
      FromDate: this.CreateDateStart,
      ToDate: this.CreateDateEnd,
      Page: { Skip: 0, Take: 10 },
      Statuses: [],
    });
    if (res.IsSuccess) {
      // this.Count = res.Value.Page.Count;
      this.Count = res.Value.Items.length;
      this.itemsInternal =
        adapter.mapMoneyTransactionPersonItemToTransactionPersonItem(
          res.Value.Items
        );

      this.IncomeInternal = 0;
      this.ConsumptionInternal = 0;
      var userId = this.Person.Id;
      this.itemsInternal.forEach((item) => {
        if (item.Status == 2) return;
        if (item.FromUserId === userId) {
          item.Amount *= -1;
          this.ConsumptionInternal += item.Amount;
        } else this.IncomeInternal += item.Amount;
      });
    }
  }

  // this.adapter.getInternalTransactionsNew({});
  // 	(res) => {
  // 		this.Count = res.Page.Count;
  // 		this.itemsInternal = res.Items;
  // 		this.IncomeInternal = 0;
  // 		this.ConsumptionInternal = 0;
  // 		var userId = this.Person.Id;
  // 		this.itemsInternal.forEach((item) => {
  // 			if (item.Status == 2) return;
  // 			if (item.FromUserId === userId) {
  // 				item.Amount *= -1;
  // 				this.ConsumptionInternal += item.Amount;
  // 			} else this.IncomeInternal += item.Amount;
  // 		});
  // 	},
  // 	formData
  // );

  async GetHistory(params: PaymentParamModel) {
    this.IncomeInternal = 0;
    this.ConsumptionInternal = 0;
    this.Income = 0;
    this.Consumption = 0;
    params.Filter.DateCreateEnd = this.CreateDateEnd.toISOString();
    params.Filter.DateCreateStart = this.CreateDateStart.toISOString();

    if (this.Type === "Account") {
      this.getTransactions(params);
    } else if (this.Type === "Card") {
      let formData = new FormData();
      formData.append("Filter[DateCreateEnd]", params.Filter.DateCreateEnd);
      formData.append("Filter[DateCreateStart]", params.Filter.DateCreateStart);

      const adapter = AdapterTransactionService.getInstance();
      const res = await adapter.getHistory({
        DateCreateEnd: this.CreateDateEnd,
        DateCreateStart: this.CreateDateStart,
        Page: { Skip: 0, Take: 100 },
      });

      if (res.IsSuccess) {
        this.itemsInternal =
          adapter.mapMoneyModelTransactionToTransactionPersonItem(
            res.Value.Items
          );
        // this.Count = res.Value.Page.Count;
        this.Count = res.Value.Items.length;
        res.Value.Items.forEach((item) => {
          if (item.Status == 2) return;
          if (this.Type == "Account") {
            if (item.FromWhomId != this.Person.Id) item.Amount *= -1;
            if (item.Amount > 0) this.IncomeInternal += item.Amount;
            else this.ConsumptionInternal += item.Amount;
          } else {
            if (item.Amount > 0) this.Income += item.Amount;
            else this.Consumption += item.Amount;
          }
        });
      }

      // this.$api.Main.NaturalPersonPaymentsService.GetHistory((res) => {
      // 	// this.itemsInternal = res.Items;
      // 	// this.Count = res.Count;
      // 	res.Items.forEach((item) => {
      // 		if (item.Status == 2) return;
      // 		if (this.Type == "Account") {
      // 			if (item.FromWhomId != this.Person.Id) item.Amount *= -1;
      // 			if (item.Amount > 0) this.IncomeInternal += item.Amount;
      // 			else this.ConsumptionInternal += item.Amount;
      // 		} else {
      // 			if (item.Amount > 0) this.Income += item.Amount;
      // 			else this.Consumption += item.Amount;
      // 		}
      // 	});
      // }, formData);
    }
  }

  async UpdateCardTransaction() {
    // // console.log("UpdateCardTransaction");
    // console.log(this.IsUpdateAvailable());
    // if (!this.IsUpdateAvailable()) {
    // 	return false;
    // }

    var formData = new FormData();
    formData.append("dateTime", this.CreateDateEnd.toISOString());
    const adapter = AdapterTransactionService.getInstance();
    const res = await adapter.updateCardTransaction(this.CreateDateEnd);
    if (res.IsSuccess) {
      console.log(res);
      if (res.Value.length === 0) {
        cookieService.setCookie(
          this.CookieTransactionUpd,
          new Date().toUTCString()
        );
        return;
      }

      this.items = adapter.mapModelTransactionToUpdateCardTransactionModel(
        res.Value
      );
      this.Count += res.Value.length;
      this.Income = 0;
      this.Consumption = 0;
      res.Value.forEach(function (item) {
        if (item.FromWhomId != this.Person.Id && item.Amount > 0) {
          item.Amount = -1 * item.Amount;
        }
        if (item.Amount > 0) this.Income += item.Amount;
        else this.Consumption += item.Amount;
      });
      cookieService.setCookie(
        this.CookieTransactionUpd,
        new Date().toUTCString()
      );
    }
  }

  ChangeDate(date: DatePeriodModel) {
    console.log("ChangeDate");
    this.isOpenDatepicker = false;
    this.CreateDateStart = date.StartDate;
    this.CreateDateEnd = date.EndDate;
    if (this.CreateDateEnd && this.CreateDateStart) {
      var params = new PaymentParamModel({ Skip: 0, Take: 10 });
      this.GetHistory(params);
    }
  }
  FormatDate(date: Date) {
    return moment(date).format("DD.MM.YYYY");
  }

  async DownloadTransactions(type: string) {
    let formData = new FormData();
    formData.append(
      "Filter[DateCreateStart]",
      this.FormatDate(this.CreateDateStart)
    );
    formData.append(
      "Filter[DateCreateEnd]",
      this.FormatDate(this.CreateDateEnd)
    );
    formData.append("type", type);
    formData.append("typeTransaction", this.Type);
    let docType = EnumDocumentType.Csv;
    switch (type) {
      case "csv":
        docType = EnumDocumentType.Csv;
        break;
      case "pdf":
        docType = EnumDocumentType.Pdf;
        break;

      default:
        break;
    }
    const adapter = AdapterTransactionService.getInstance();
    const res = await adapter.downloadTransactionsForNatural(
      new DownloadTransactionsForNaturalRequest({
        DocumentType: docType,
        ByCard: undefined,
        FromDate: this.CreateDateStart,
        ToDate: this.CreateDateEnd,
        Page: undefined,
        TypeTransactionId: undefined,
      })
    );
    if (res.IsSuccess) {
      // Создание нового элемента <a> на лету
      const filePath = res.Value as string;
      const link = document.createElement("a");
      link.href = window.location.origin + filePath; // Устанавливаем путь к файлу
      console.log(
        "window.location.origin + filePath",
        window.location.origin + filePath
      );
      link.download = filePath.split("/").pop(); // Установка имени файла для сохранения

      // Эмуляция клика по ссылке
      document.body.appendChild(link);
      link.click();

      // После клика ссылку можно удалить
      document.body.removeChild(link);
    }
  }

  getParameterByName(name: string, url: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  UpdateTransactionHash() {
    var nameCookie = "lastUpdTransDays";
    var lastUpdateTransaction = cookieService.getCookie(nameCookie);
    if (
      !lastUpdateTransaction ||
      this.getDayDelta(new Date(lastUpdateTransaction), true) >= 1
    ) {
      console.log("updateTrans");
      this.UpdateCardTransaction();
      cookieService.setCookie(nameCookie, new Date().toUTCString());
    }
  }
  IsUpdateAvailable() {
    var lastUpdateTransaction = CookieService.getCookie(
      this.CookieTransactionUpd
    );

    if (!lastUpdateTransaction) {
      return true;
    } else {
      if (this.getDayDelta(new Date(lastUpdateTransaction), false) >= 1) {
        return true;
      } else return false;
    }
  }
  getDayDelta(oldDate: Date, isDay: boolean) {
    var newDate = new Date().getTime(); // Current date now.
    if (isDay) {
      var days = (newDate - oldDate.getTime()) / 1000 / 60 / 60 / 24;
      return days;
    } else {
      var hours = (newDate - oldDate.getTime()) / 1000 / 60 / 60;
      return hours;
    }
  }

  clickCalendar() {
    this.IsPeriod = false;
    this.isOpenDatepicker = true;
  }
}
