import ApiService from "@/api/index";
import CurrentUserResponse from "@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse";
import CheckUniqueEmailRequest from "@/api/main/dataSource/api/aba/public/services/models/registration/CheckUniqueEmailRequest";
import RegistertrationStartRequest from "@/api/main/dataSource/api/aba/public/services/models/registration/RegistertrationStartRequest";
import RegistrationRequest from "@/api/main/dataSource/api/aba/public/services/models/registration/RegistrationRequest";
import RestorePasswordRequest from "@/api/main/dataSource/api/aba/public/services/models/restore-password/RestorePasswordRequest";

import { notify } from "@/app/notifications";

import { ref } from "vue";
import ErrorModel from "./models/errorModel";
import LoginRequest from "@/api/main/dataSource/api/aba/public/services/models/login/LoginRequest";
import LoginResponse from "@/api/main/dataSource/api/aba/public/services/models/login/LoginResponse";
import WsResponseModel from "@/api/main/SchemaBuilderServer/Models/WsResponseModel";
import AcquiringPayoutTemplateListResponse from "@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse";
import RecoverPasswordRequestRequest from "@/api/main/dataSource/api/aba/public/services/models/restore-password/RecoverPasswordRequestRequest";

export default class AdapterLoginService {
  /**
   * Сервис
   */
  private static instance: AdapterLoginService;
  /**
   * API
   */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;

  constructor() {
    AdapterLoginService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterLoginService.instance) {
      AdapterLoginService.instance = new AdapterLoginService();
    }
    return AdapterLoginService.instance;
  }

  async checkUniqueEmal(
    value: CheckUniqueEmailRequest
  ): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.checkUniqueEmalAsync(
          value
        );
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkUniqueEmal");
    }
    return result;
  }

  async registerStart(
    value: RegistertrationStartRequest
  ): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.registerStartAsync(
          value
        );
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR registerStart");
    }
    return result;
  }

  async loginUser(
    value: LoginRequest
  ): Promise<WsResponseModel<LoginResponse>> {
    const result = new WsResponseModel<LoginResponse>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.loginAsync(value);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR loginUser");
    }
    return result;
  }

  async logoutUser(): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.logOutAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  async registerEnd(
    req: RegistrationRequest
  ): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.registerEndAsync(
          req
        );
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR registerEnd");
    }
    return result;
  }

  async recoverPassword(
    req: RestorePasswordRequest
  ): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.recoverPasswordAsync(
          req
        );
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR recoverPasswor");
    }
    return result;
  }
  async recoverPasswordRequest(
    req: RecoverPasswordRequestRequest
  ): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.recoverPasswordRequestAsync(
          req
        );
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR ecoverPasswordRequest");
    }
    return result;
  }

  async getCurrentUser(): Promise<WsResponseModel<CurrentUserResponse>> {
    const result = new WsResponseModel<CurrentUserResponse>();
    try {
      const resp =
        await AdapterLoginService.api.Main.AuthWsController.getCurrentUserAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  async getAcquiringTemplates(): Promise<
    WsResponseModel<AcquiringPayoutTemplateListResponse>
  > {
    const result = new WsResponseModel<AcquiringPayoutTemplateListResponse>();
    try {
      const resp =
        await AdapterLoginService.api.Main.NaturalPaymentsWsController.getAcquiringTemplatesAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getAcquiringTemplates");
    }
    return result;
  }

  /**
   * Универсальный обработчик ошибок
   * @param e
   * @param context
   * @returns
   */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || "Internal Server Error",
      Value: null,
    };
  }
}
