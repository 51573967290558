
import GetRefillRequisitesResponse from "@/api/main/dataSource/api/aba/cabinet/web/models/GetRefillRequisitesResponse";
import ModelPersonBalance from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import GetNewUserResponse from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUserResponse";
import { Vue, Options, Prop } from "vue-property-decorator";
import { AdapterBalanceService } from "../adapter";
import { ref } from "vue";
@Options({
  name: "refill",
})
export default class Refill extends Vue {
  @Prop({ default: false }) visible: boolean;
  @Prop({ default: null }) Profile: GetNewUserResponse;
  @Prop({ default: null }) Balance: ModelPersonBalance;

  ErrorMessage: string = "";
  IsSuccess: boolean = false;
  SWIFT: string = "";
  Address: string = "";
  PayeeName: string = "";
  IBAN: string = "";
  adapter = AdapterBalanceService.getInstance();
  async mounted() {
    await this.loadSettings();
    await this.GetRequisites();
    console.log(`refill`);
    this.replace();
    this.IBAN = this.Balance.Number;
  }

  async GetRequisites() {
    const res = await this.adapter.getRefillRequisites();

    if (res instanceof GetRefillRequisitesResponse) {
      this.SWIFT = res.Swift;
      this.Address = res.Address;
      this.PayeeName = res.PayeeName;
      if (res.Iban) this.IBAN = res.Iban;
    }
  }

  close() {
    this.$emit("close");
  }
  replacedString = "";

  replace() {
    debugger;
    const original = this.topUpByTransfer.value.PaymentDetails;
    this.replacedString = original.replace(
      "{AccountNumber}",
      this.Balance.Number
    );
  }

  topUpByTransfer: any = ref({});
  async loadSettings() {
    try {
      // Запрашиваем файл appWebSettings.json из папки public
      const response = await fetch("/appWebSettings.json");

      // Проверяем, что запрос успешен
      if (!response.ok) {
        throw new Error("Error");
      }

      // Преобразуем ответ в JSON
      const settings = await response.json();
      this.topUpByTransfer.value = settings.TopUpByTransfer;
      // Сохраняем нужные параметры в переменные
    } catch (error) {
      console.error("Error:", error);
    }
  }
}
